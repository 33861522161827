import { Text, useColorModeValue } from "@chakra-ui/react";
import * as React from "react";
import { memo } from "react";

export const Logo = memo(() => {
  return (
    <Text
      color={useColorModeValue("gray.800", "whiteAlpha.900")}
      fontSize="18px"
      fontFamily="'Comic Sans MS', cursive, Roboto"
      ml={3}
    >
      大明 &amp; 小银
    </Text>
  );
});
