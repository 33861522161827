import { Box, Link } from '@chakra-ui/react';
import * as React from 'react';
import { useMemo, memo } from 'react';

export const Footer = memo(() => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <>
      <Box textAlign="center" opacity={0.4} fontSize="sm">
        &copy; {currentYear} Arthur Ming. All Rights Reserved.
      </Box>
      <Box textAlign="center" opacity={0.4} fontSize="sm">
        Copy from{' '}
        <Link href="https://www.craftz.dog/" target="_blank">
          Takuya Matsuyama
        </Link>
      </Box>
    </>
  );
});
