import { Box, Spinner } from "@chakra-ui/react";
import * as React from "react";
import { forwardRef, memo } from "react";

export const WeddingDaySpinner = memo(() => (
  <Spinner
    size="xl"
    position="absolute"
    left="50%"
    top="50%"
    ml="calc(0px - var(--spinner-size) / 2)"
    mt="calc(0px - var(--spinner-size))"
  />
));


export const WeddingDayContainer = forwardRef<HTMLDivElement>(
  ({ children }: { children?: React.ReactNode }, ref) => (
    <Box
      ref={ref}
      className="voxel-dog"
      m="auto"
      mt={["-20px", "-60px", "-120px"]}
      mb={["-20px", "-140px", "-200px"]}
      w={[280, 480, 640]}
      h={[280, 480, 640]}
      position="relative"
    >
      {children}
    </Box>
  )
);

export const WeddingDayLoader = () => {
  return (
    //@ts-ignore
    <WeddingDayContainer>
      <WeddingDaySpinner />
    </WeddingDayContainer>
  );
};
