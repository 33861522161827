import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  List,
  ListIcon,
  ListItem,
  useDisclosure,
  Link
} from '@chakra-ui/react';
import * as React from 'react';
import { BsMap } from 'react-icons/bs';
import { getMapApp, ILocation, MapType } from '../lib/map';

export const Address: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loc: ILocation = { lng: 120.94, lat: 32.06 };
  const mapList = [
    {
      name: '腾讯地图',
      type: 'qq' as MapType
    },
    {
      name: '高德地图',
      type: 'amap' as MapType
    },
    {
      name: '百度地图',
      type: 'baidu' as MapType
    }
  ];

  return (
    <>
      <Box onClick={onOpen}>江苏省南通市通州区兴仁邮政支局向南约50米</Box>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <List spacing={3}>
              {mapList.map(m => (
                <ListItem key={m.name}>
                  <Text textAlign="center">
                    <Link
                      href={getMapApp(m.type, loc, '南通市通州区兴仁镇鼎香阁')}
                      target="_blank"
                      textAlign="center"
                    >
                      {m.name}
                    </Link>
                  </Text>
                </ListItem>
              ))}
            </List>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
