export type MapType = 'qq' | 'amap' | 'baidu';
export interface ILocation {
  lat: number;
  lng: number;
}

// link: https://www.jianshu.com/p/3da99256d8d3
export const getMapApp = (
  mapType: MapType,
  loc: ILocation,
  address: string
): string => {
  const { lat, lng } = loc;

  let url = '';
  switch (mapType) {
    case 'qq':
      url =
        'https://apis.map.qq.com/uri/v1/marker?marker=coord:' +
        lat +
        ',' +
        lng +
        ';addr:' +
        address +
        ';title:地址&referer=keyfree';
      break;
    case 'amap':
      url =
        'https://uri.amap.com/marker?position=' +
        lng +
        ',' +
        lat +
        '&name=' +
        address +
        '&callnative=1';
      break;
    case 'baidu':
      url =
        'http://api.map.baidu.com/marker?location=' +
        lat +
        ',' +
        lng +
        '&title=地址&content=' +
        address +
        '&output=html&src=webapp.reformer.appname&coord_type=gcj02';
      break;
    default:
      break;
  }

  return url;
};
