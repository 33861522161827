import {
  Box,
  Container,
  Flex,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { Logo } from "./Logo";
import { ToggleThemeButton } from "./ToggleThemeButton";

export const Navbar = () => {
  return (
    <Box
      position="fixed"
      as="nav"
      w="100%"
      bg={useColorModeValue("#ffffff40", "#20202380")}
      css={{ backdropFilter: "blur(10px)" }}
      zIndex={2}
    >
      <Container display="flex" p={2} maxW="container.md">
        <Flex align="center" mr={5}>
          <Heading
            as="h1"
            size="lg"
            letterSpacing={"tighter"}
            fontWeight="bold"
            fontSize="18px"
          >
            <Logo />
          </Heading>
        </Flex>
        <Box flex={1}></Box>

        <ToggleThemeButton />
      </Container>
    </Box>
  );
};
