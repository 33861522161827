import { Box, Container } from "@chakra-ui/react";
import React from "react";
import { Footer } from "./components/Footer";
import { Main } from "./components/Main";
import { Navbar } from "./components/Navbar";
import { WeddingDayLoader } from "./components/WeddingDayLoader";
import logo from "./logo.svg";

const LazyWeedingDay = React.lazy(( ()=> import('./components/WeddingDay')));

function App () {
  return (
    <Box as="main"  pb={8}>
      <Navbar></Navbar>
      <Container maxW="container.md" pt={14}>
        <React.Suspense fallback={<WeddingDayLoader/>}>
          <LazyWeedingDay />
        </React.Suspense>
        <Main></Main>
        <Footer/>
      </Container>
    </Box>
  );
}

export default App;
