import {
  Box,
  Container,
  Heading,
  Link,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import * as React from 'react';
import { Address } from './Address';
import { Section } from './Section';

export const Main: React.FC = () => {
  return (
    <Container fontFamily="'Comic Sans MS', cursive, Roboto">
      <Box
        borderRadius="lg"
        mb={6}
        p={3}
        textAlign="center"
        bg={useColorModeValue('whiteAlpha.500', 'whiteAlpha.200')}
        css={{ backdropFilter: 'blur(10px)' }}
      >
        <Text>大家好，我明国宾和朱成颖</Text>
        <Text>正式邀请你来参加我们的婚礼</Text>
      </Box>

      <Section delay={1}>
        <Heading as="h3" variant="section-title">
          时间
        </Heading>
        <Box>2022年 11月 22日（农历小雪）</Box>
      </Section>
      <Section delay={2}>
        <Heading as="h3" variant="section-title">
          地址
        </Heading>
        <Box>
          <Address />
        </Box>
      </Section>
    </Container>
  );
};
